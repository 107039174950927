import { HMSRoomProvider } from '@100mslive/react-sdk';
import LiveBox from '@components/LiveBox';
import useFetchRooms from '@hooks/useFetchRooms';

const LiveMonitoring = () => {
	const rooms = useFetchRooms();
	if (!rooms.length) return <div>No rooms found</div>;

	// const rooms = [{ alias: 'vki-weat-xzr', student_detail: { user_id: 1212, class_code: '9th', name: 'test', user_uuid: '' } }];

	return (
		<div style={{ height: '100%', width: '100%' }}>
			<div
				style={{
					display: 'grid',
					gridTemplateColumns: 'repeat(7, 1fr)',
					gap: '40px',
					height: '100%',
					width: '100%',
				}}
			>
				{rooms.map((room, index) => {
					return (
						<HMSRoomProvider key={`${room?.student_detail?.user_id}-${index}` || `${room?.student_detail?.class_code}-${index}`}>
							<LiveBox studentDetails={room.student_detail} roomCode={room?.alias} />
						</HMSRoomProvider>
					);
				})}
			</div>
		</div>
	);
};

export default LiveMonitoring;
