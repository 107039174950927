import useSeriesData from '@hooks/useSeriesData';
import { Box, TextField, Autocomplete, FormGroup, FormControlLabel, Switch } from '@mui/material';
import { Create } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';
import { Controller } from 'react-hook-form';

export const UserPreferenceCreate: React.FC = () => {
	const {
		saveButtonProps,
		register,
		formState: { errors },
		control,
	} = useForm();

	const seriesList = useSeriesData();

	return (
		<Create saveButtonProps={saveButtonProps}>
			<Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
				<TextField
					type="number"
					{...register('user_id', {
						required: 'Required',
						valueAsNumber: true,
					})}
					error={!!errors.user_id}
					helperText={errors.user_id?.message ? `${errors.user_id?.message}` : undefined}
					margin="normal"
					fullWidth
					label="UserId"
					name="user_id"
					autoFocus
				/>
				<TextField
					{...register('name')}
					error={!!errors.name}
					helperText={errors.name?.message ? `${errors.name?.message}` : undefined}
					margin="normal"
					fullWidth
					label="Name"
					name="name"
				/>
				<TextField
					{...register('pref_lang')}
					error={!!errors.pref_lang}
					helperText={errors.pref_lang?.message ? `${errors.pref_lang?.message}` : undefined}
					margin="normal"
					fullWidth
					label="PrefLang"
					name="pref_lang"
				/>
				<TextField
					{...register('target_content_tags')}
					error={!!errors.target_content_tags}
					helperText={errors.target_content_tags?.message ? `${errors.target_content_tags?.message}` : undefined}
					margin="normal"
					fullWidth
					InputLabelProps={{ shrink: true }}
					label="Target content tags"
					name="target_content_tags"
				/>
				<TextField
					{...register('chat_slack_handler')}
					error={!!errors.chat_slack_handler}
					helperText={errors.chat_slack_handler?.message ? `${errors.chat_slack_handler?.message}` : undefined}
					margin="normal"
					fullWidth
					label="ChatSlackHandler"
					name="chat_slack_handler"
				/>
				<TextField
					{...register('chat_start_time')}
					error={!!errors.chat_start_time}
					helperText={errors.chat_start_time?.message ? `${errors.chat_start_time?.message}` : undefined}
					margin="normal"
					fullWidth
					label="ChatStartTime"
					name="chat_start_time"
				/>
				{seriesList ? (
					<Controller
						control={control}
						name="current_series_uuid"
						render={({ field }) => (
							<Autocomplete
								{...field}
								options={seriesList}
								onChange={(_, value) => {
									field.onChange(value?.uuid);
								}}
								getOptionLabel={(item) => {
									return seriesList?.find((p: any) => p?.uuid?.toString() === item?.uuid?.toString())?.name ?? '';
								}}
								isOptionEqualToValue={(option, value) =>
									value === undefined || option?.uuid?.toString() === (value?.uuid ?? value)?.toString()
								}
								renderInput={(params) => (
									<TextField
										{...params}
										label="current_series_uuid"
										margin="normal"
										variant="outlined"
										error={!!errors?.current_series_uuid}
										helperText={errors.current_series_uuid?.message ? `${errors.current_series_uuid?.message}` : undefined}
										required
									/>
								)}
							/>
						)}
					/>
				) : null}
				<TextField
					{...register('current_module_uuid')}
					error={!!errors.current_module_uuid}
					helperText={errors.current_module_uuid?.message ? `${errors.current_module_uuid?.message}` : undefined}
					margin="normal"
					fullWidth
					label="CurrentModuleUUID"
					name="current_module_uuid"
				/>
				<FormGroup sx={{ marginTop: '16px' }}>
					<FormControlLabel control={<Switch {...register('link_on')} />} label="LinkOn" />
				</FormGroup>
			</Box>
		</Create>
	);
};
