import React from 'react';
import { useDataGrid, List, EditButton } from '@refinedev/mui';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { IAssignmentQuestion } from 'interfaces';

export const UserPreferenceList: React.FC = () => {
	const columns: GridColDef<any>[] = [
		{
			field: 'user_id',
			headerName: 'UserId',
			type: 'number',
			width: 150,
			renderCell: ({ row }: any) => {
				return row.user_id + '';
			},
		},
		{
			field: 'name',
			headerName: 'Name',
			minWidth: 150,
			flex: 1,
			sortable: false,
			filterable: false,
		},
		{
			field: 'pref_lang',
			headerName: 'PrefLang',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'chat_start_time',
			headerName: 'ChatStartTime',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'current_series_uuid',
			headerName: 'CurrentSeries',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'current_series_info.title',
			headerName: 'CurrentSeriesInfo',
			minWidth: 150,
			flex: 1,
			sortable: false,
			filterable: false,
			renderCell: ({ row }: any) => {
				return row.current_series_info ? row.current_series_info.title + '' : '';
			},
		},
		{
			field: 'current_module_uuid',
			headerName: 'CurrentModule',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'target_content_tags',
			headerName: 'Target content tags',
			minWidth: 150,
			flex: 1,
		},
		{
			field: 'extra_config.max_chat_time',
			headerName: 'MaxChatTime',
			minWidth: 150,
			flex: 1,
			renderCell: ({ row }: any) => {
				return row.extra_config ? row.extra_config.max_chat_time : '';
			},
		},
		{
			field: 'extra_config.app_opt_out',
			headerName: 'Android App Opt Out',
			minWidth: 150,
			flex: 1,
			renderCell: ({ row }: any) => {
				return row.extra_config?.app_opt_out ? `${row.extra_config.app_opt_out}` : 'False';
			},
		},
		{
			field: 'link_on',
			headerName: 'LinkOn',
			minWidth: 100,
			flex: 1,
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			renderCell: function render({ row }) {
				return <EditButton hideText recordItemId={row.id} />;
			},
			align: 'center',
			headerAlign: 'center',
			minWidth: 80,
		},
	];

	const { dataGridProps } = useDataGrid<IAssignmentQuestion>({
		initialCurrent: 1,
		initialPageSize: 20,
		initialSorter: [
			{
				field: 'user_id',
				order: 'desc',
			},
		],
		syncWithLocation: true,
	});

	return (
		<List>
			<DataGrid columns={columns} {...dataGridProps} autoHeight={true} getRowHeight={() => 130} />
		</List>
	);
};
