import { HMSConfig, HMSLogLevel, selectRemotePeers, useHMSActions, useHMSStore, useVideo } from '@100mslive/react-sdk';
import { FC, memo, useEffect, useMemo, useState } from 'react';
import SpeakerIcon from '../assets/speaker.png';
import './styles.css';
import MicIcon from '../assets/microphone.png';

const hmsActionWrapper = async (fuc: any) => {
	try {
		await fuc();
	} catch (err) {
		console.error(`Error: ${fuc}`, err);
	}
};

const VideoTile = (props: any) => {
	const { videoRef } = useVideo({
		trackId: props.videoTrack,
	});

	return (
		<video
			ref={videoRef}
			autoPlay
			playsInline
			style={{
				width: '200px',
				height: '200px',
				borderRadius: '12px',
			}}
		></video>
	);
};

interface LiveBoxProps {
	roomCode: string;
	studentDetails: {
		user_id: number;
		name: string;
		class_code: string;
		user_uuid: string;
	};
}

const LiveBox: FC<LiveBoxProps> = (props: LiveBoxProps) => {
	const hmsActions = useHMSActions();
	const peers = useHMSStore(selectRemotePeers);
	const videoTrack = useMemo(() => {
		for (const key in peers) {
			if (props.studentDetails.user_uuid === peers[key].customerUserId) return peers[key].videoTrack;
		}
	}, [peers]);
	const audioTrack = useMemo(() => {
		for (const key in peers) {
			if (props.studentDetails.user_uuid === peers[key].customerUserId) return peers[key].audioTrack;
		}
	}, [peers]);
	const [isSpeakerOff, setIsSpeakerOff] = useState(true);
	const [isRemoteUserMuted, setIsRemoteUserMuted] = useState(true);

	const beforeJoining = async () => {
		if (!hmsActions) return;
		await hmsActionWrapper(() => hmsActions.setLocalAudioEnabled(false));
		await hmsActionWrapper(() => hmsActions.setLocalVideoEnabled(false));
		await hmsActionWrapper(() =>
			hmsActions.setAudioSettings({
				volume: 0,
			}),
		);
		await hmsActionWrapper(() => hmsActions.setVolume(0));
	};

	const joinConfig = (userName: string, authToken: string) => {
		const config: HMSConfig = {
			userName: userName,
			authToken: authToken,
			settings: {
				isVideoMuted: true,
				isAudioMuted: true,
			},
			alwaysRequestPermissions: true,
			rememberDeviceSelection: true,
			autoManageVideo: false,
		};
		return config;
	};

	const joinLiveSession = async () => {
		if (!props.roomCode || !hmsActions) return;

		try {
			hmsActions.setLogLevel(HMSLogLevel.ERROR);
			const authToken = await hmsActions.getAuthTokenByRoomCode({ roomCode: props.roomCode, userId: `panel-${props.roomCode}` });
			await hmsActionWrapper(() => hmsActions.join(joinConfig(`panel-user-${props.roomCode}`, authToken)));
			await hmsActionWrapper(() => hmsActions.unblockAudio());
			await hmsActionWrapper(() => hmsActions.setLocalVideoEnabled(false));
			await hmsActionWrapper(() => hmsActions.setLocalAudioEnabled(false));
			await hmsActionWrapper(() => hmsActions.setAudioSettings({ volume: 0 }));
			await hmsActionWrapper(() => hmsActions.setVolume(0));
		} catch (error) {
			console.error('Error getting auth token:', error);
		}
	};

	const handleJoinRoom = async (isSpeakerOffParams) => {
		if (!hmsActions) return;
		hmsActions?.setLogLevel(HMSLogLevel.ERROR);
		if (isSpeakerOffParams) {
			await hmsActionWrapper(() => hmsActions.setVolume(0));
			await hmsActionWrapper(() => hmsActions?.setAudioSettings({ volume: 0 }));
		} else {
			await hmsActionWrapper(() => hmsActions.setVolume(100));
			await hmsActionWrapper(() => hmsActions?.setAudioSettings({ volume: 100 }));
			await hmsActionWrapper(() => hmsActions?.unblockAudio());
		}
	};

	const handleSpeaker = async () => {
		setIsSpeakerOff((off) => {
			handleJoinRoom(!off);
			return !off;
		});
	};
	const handleRemoteUserMute = async () => {
		setIsRemoteUserMuted((muted) => {
			hmsActionWrapper(() => hmsActions?.setLocalAudioEnabled(muted));
			return !muted;
		});
	};

	const renderSpeakerIcon = () => {
		return (
			<div onClick={handleSpeaker} className="speaker_icon">
				{isSpeakerOff && <div className="muted_speaker"></div>}
				<img className="icon_img" src={SpeakerIcon} alt="speaker" />
			</div>
		);
	};
	const renderRemoteUserMuteIcon = () => {
		return (
			<div onClick={handleRemoteUserMute} className="remote_user_icon">
				{isRemoteUserMuted && <div className="muted_speaker"></div>}
				<img className="icon_img" src={MicIcon} alt="speaker" />
			</div>
		);
	};

	useEffect(() => {
		if (!props.roomCode) return;
		beforeJoining().then(() => {
			joinLiveSession();
		});
		return () => {
			hmsActions.leave().catch(() => {
				//
			});
		};
	}, [props.roomCode, hmsActions]);

	if (!videoTrack && !audioTrack) return null;

	return (
		<div
			style={{
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				position: 'relative',
				background: 'black',
			}}
		>
			{renderSpeakerIcon()}
			{renderRemoteUserMuteIcon()}
			<VideoTile videoTrack={videoTrack} />
			<div className="user_details">
				<p>Name: {props.studentDetails.name}</p>
				<p>
					Class: {props.studentDetails.class_code}, User Id: {props.studentDetails.user_id}
				</p>
			</div>
		</div>
	);
};

export default memo(LiveBox);
